<!--
--------------------------------------------------------------------------
    App.vue
--------------------------------------------------------------------------

    Main component

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div id="main-wrapper" :class="$store.state.frameClass">
        <div v-if="!$store.state.outOfService">
            <topHeader />
            <mainContent />
            <bottomFooter />
        </div>

        <div v-else>
            <outOfService></outOfService>
        </div>

        <VueElementLoading :active="$store.state.showLoading" :is-full-screen="true" :text="$store.state.loadingText" spinner="ring" color="#007BC0" size="64" background-color="rgba(255, 255, 255, 1)" />
    </div>
</template>

<script>
    import TopHeader from "./components/TopHeader";
    import MainContent from "./components/MainContent";
    import BottomFooter from "./components/BottomFooter";
    import OutOfService from "./components/OutOfService";
    import VueElementLoading from 'vue-element-loading';

    export default {
        name: 'app',
        components: { TopHeader, MainContent, BottomFooter, OutOfService, VueElementLoading },
        data() {
            return {
                languagesLoaded: false
            }
        },
        /**
         * When app created, set data
         */
        async created() {
            // Show loading
            this.$store.commit('showLoading', this.$gettext('FUTUR'));

            // Get languages
            await this.getAvailableLanguages();

            // Try to get user data
            await this.getUserData();

            // Set language
            this.setLanguage();

            // Set title and header metadata
            this.setMetadata(this.$gettext(this.$store.state.metadata.title) + '. ' + this.$gettext(this.$store.state.metadata.subtitle), this.$gettext(this.$store.state.metadata.description), this.$gettext(this.$store.state.metadata.keywords));

            // Set RSS link
            let rssLink = document.createElement('link');
            rssLink.setAttribute('type', 'application/rss+xml');
            rssLink.setAttribute('rel', 'alternate');
            rssLink.setAttribute('href', this.$store.state.config.URL + '/RSS?lang=' + this.$language.current);
            document.head.appendChild(rssLink);

            // Set routes
            this.setFixedRoutes();

            // Get sections
            await this.getSections();

            // Add items routes
            this.$router.addRoute({ path: '/:itemId+', name: 'item', component: () => import(/* webpackChunkName: "changelog" */ './views/Item'), beforeEnter: (to, from, next) => { this.initsHeaderSearchLanguage('', '', next) } });

            // Get item templates
            await this.getItemTemplates();

            // Hide loading
            this.$store.commit('closeLoading');

            // Check frame parameter
            if (location.href.includes('frame/1')) this.$store.commit('setFrame', 1);
            else this.$store.commit('setFrame', 0);
        },
        updated() {
            this.$root.$emit('fixHomeHeights');
        },
        methods: {
            /**
             * Get avalialbe languages for the application
             */
            async getAvailableLanguages() {
                // Get available languages
                let availableLanguages = {};
                let vm = this;
                let response = await this.axios.get(this.apiURL + '/languages').then().catch(function(error) {
                    // Catch network error
                    if (!error.status) {
                        // Hide loading and show out of service
                        vm.$store.commit('closeLoading');
                        vm.$store.commit('setOutOfService', true);
                    }
                });

                // Process results and set available languages
                if (response && response.data) response.data.forEach(lang => availableLanguages[lang.shortName] = lang.fullName);
                else availableLanguages = { ca: 'Català', en: 'English', es: 'Español' };

                // Set available languages
                this.$language.available = availableLanguages;
                this.languagesLoaded = true;
            },
            /**
             * Set language
             */
            setLanguage() {
                // Get default language from cookies or browser
                let language = '';
                if (!language || language.trim() === '' && this.$route.query.locale) language = this.$route.query.locale;
                if ((!language || language.trim() === '') && this.$store.state.user.username != '') language = this.$store.state.user.language;
                if ((!language || language.trim() === '') && this.$cookies.isKey('futur.public.language')) language = this.$cookies.get('futur.public.language');
                if ((!language || language.trim() === '') && this.$store.state.config.defaultLanguage != '') language = this.$store.state.config.defaultLanguage;
                if (!language || language.trim() === '') language = navigator.language;
                if (!language || language.trim() === '' || Object.keys(this.$language.available).find(l => l === language) === undefined) language = 'ca';
                this.$language.current = language;
                this.$cookies.set('futur.public.language', language, -1, '/');
            },
            /**
             * Sets fixed routes
             */
            setFixedRoutes() {
                // Add general and simulations routes
                let currentLanguage = this.$language.current;
                Object.keys(this.$language.available).forEach(l => {
                    this.$language.current = l;

                    // General routes
                    this.$router.addRoute({ path: this.$gettext('/news'), name: 'changelog-' + l, component: () => import(/* webpackChunkName: "changelog" */ './views/Changelog'), beforeEnter: (to, from, next) => { this.initsHeaderSearchLanguage('', l, next) } });
                    this.$router.addRoute({ path: this.$gettext('/personal_corner'), name: 'personal-corner-' + l, component: () => import(/* webpackChunkName: "personal-corner" */ './views/PersonalCorner'), beforeEnter: (to, from, next) => { this.initsHeaderSearchLanguage('', l, next) } });
                    this.$router.addRoute({ path: this.$gettext('/sitemap'), name: 'sitemap-' + l, component: () => import(/* webpackChunkName: "sitemap" */ './views/Sitemap'), beforeEnter: (to, from, next) => { this.initsHeaderSearchLanguage('', l, next) } });
                    this.$router.addRoute({ path: this.$gettext('/search') + '/:paramValues*', name: 'search-' + l, component: () => import(/* webpackChunkName: "search" */ './views/Search'), beforeEnter: (to, from, next) => { this.initsHeaderSearchLanguage('', l, next) } });
                    this.$router.addRoute({ path: this.$gettext('/notFound'), name: 'not-found-' + l, component: () => import(/* webpackChunkName: "search" */ './views/NotFound'), beforeEnter: (to, from, next) => { this.initsHeaderSearchLanguage('', l, next) } });

                    // Simulations route
                    this.$router.addRoute({ path: this.$gettext('/simulations') + '/:simulationId+', name: 'simulation-' + l, component: () => import(/* webpackChunkName: "changelog" */ './views/Simulation'), beforeEnter: (to, from, next) => { this.initsHeaderSearchLanguage('', '', next) } });
                });
                this.$language.current = currentLanguage;
            },
            /**
             * Get sections
             */
            async getSections() {
                // Get sections
                let vm = this;
                let response = await this.axios.get(this.apiURL + '/sections').then().catch(function(error) {
                    // Catch network error
                    if (!error.status) {
                        // Hide loading and show out of service
                        vm.$store.commit('closeLoading');
                        vm.$store.commit('setOutOfService', true);
                    }
                });

                // Process results and set available subtypes
                if (response && response.data) {
                    // Get and sort sections
                    let sections = response.data.sort(function (a, b) {
                        if (a.menu !== null && b.menu === null) return -1;
                        else if (a.menu === null && b.menu !== null) return 1;
                        else {
                            if (a.ord > b.ord) return 1;
                            else return -1;
                        }
                    });

                    // For sections of type item and in main menu, search num results
                    sections.filter(s => s.type === 'item' && s.menu === 'main').forEach(s => {
                        let formData = {
                            query: s.numberParams !== null ? s.numberParams[this.$language.current] : s.content[this.$language.current],
                            facets: [],
                            page: {
                                pageIdx: 0,
                                pageSize: 1,
                                total: 0
                            }
                        };

                        // Search number of items
                        s.numResults = 0;
                        this.axios.post(this.apiURL + '/items/search', formData)
                            .then(response => {
                                // Get total items
                                s.numResults = response.data.totalElements;
                            })
                            .catch(error => {
                                this.processError(error);
                            });
                    });

                    // Set sections in store
                    this.$store.commit('setSections', sections);
                    this.$root.$emit('sectionsLoaded', sections);

                    // Add sections routes
                    sections.forEach(s => {
                        Object.keys(this.$language.available).forEach(l => {
                            if (s.type === 'item') this.$router.addRoute({ path: '/' + s.path[l] + '/:paramValues*', name: 'section-' + s.id + '-' + l, component: () => import(/* webpackChunkName: "section" */ './views/SectionItem'), props: { section: s }, beforeEnter: (to, from, next) => { this.initsHeaderSearchLanguage(s.backgroundImage, l, next) } });
                            else if (s.type === 'text') this.$router.addRoute({ path: '/' + s.path[l], name: 'section-' + s.id + '-' + l, component: () => import(/* webpackChunkName: "section" */ './views/SectionText'), props: { section: s }, beforeEnter: (to, from, next) => { this.initsHeaderSearchLanguage(s.backgroundImage, l, next) } });
                        });
                    });
                } else {
                    this.$store.commit('closeLoading');
                    this.$store.commit('setOutOfService', true);
                }
            },
            /**
             * Get item templates
             */
            async getItemTemplates() {
                // Get item templates
                let vm = this;
                let response = await this.axios.get(this.apiURL + '/itemTemplates').then().catch(function(error) {
                    // Catch network error
                    if (!error.status) {
                        // Hide loading and show out of service
                        vm.$store.commit('closeLoading');
                        vm.$store.commit('setOutOfService', true);
                    }
                });

                // Process results and set available templates
                if (response && response.data) {
                    this.$store.commit('setItemTemplates', response.data);
                    response.data.forEach(it => {
                        document.styleSheets[0].addRule('.item-' + it.itemType + '-' + it.itemSubtype + ':before','color: ' + it.color + ' !important;');
                    });

                    // Send event
                    this.$root.$emit('templatesLoaded');
                    this.$store.commit('setTemplatesLoaded');
                } else {
                    this.$store.commit('closeLoading');
                    this.$store.commit('setOutOfService', true);
                }
            },
            /**
             * Gets user data
             */
            async getUserData() {
                // Get credentials
                let response = await this.axios.get(this.apiURL + '/users/getUserAuth').then().catch(function(error) { console.log(error); });
                // Check response
                if (response && response.data) {
                    this.$store.commit('setUserData', response.data);

                    // Check for user impersonation
                    if (sessionStorage.getItem('userImpersonate') !== null) {
                        // Get impersonated user data
                        let response = await this.axios.get(this.apiURL + '/users/' + sessionStorage.getItem('userImpersonate')).then().catch(function(error) { console.log(error); });
                        if (response && response.data) {
                            this.$store.commit('setRealUserData', this.$store.state.user);
                            this.$store.commit('setUserData', response.data);
                        }
                    }
                }
            }
        }
    }
</script>