<!--
--------------------------------------------------------------------------
    OutOfService.vue
--------------------------------------------------------------------------

    Component to show out of service error

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col text-left">
                <b-alert show variant="danger" class="mt-4">
                    <h4 class="alert-heading"><translate>Out of service</translate></h4>
                    <p v-translate>This website is temporarily out of service. For more information contact the administrator.</p>
                </b-alert>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'out-of-service',
        props: ['title']
    }
</script>