<!--
--------------------------------------------------------------------------
    Errors.vue
--------------------------------------------------------------------------

    Component to show errors

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div id="errors">
        <b-alert show variant="danger" class="mb-4" v-if="$store.state.errors.length > 0">
            <translate>The operation was not successful. Try it later or contact the administrator.</translate>
        </b-alert>
        <div class="d-none" v-for="(error, index) in $store.state.errors" :key="'error.' + index">
            {{ error }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'errors'
    }
</script>