/**
 *
 * index.js
 *
 * Vue mixins initialization
 *
 * @author Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.
 *
 */

// Mixins data
let apiURL = '';
if (process.env.NODE_ENV === 'production') {
    apiURL = 'https://futur.upc.edu/api';
    //apiURL = 'https://futurdev.upc.edu/api';
} else {
    apiURL = 'http://verdaguer.upc.edu:7004';
    //apiURL = 'http://verdaguer.upc.edu:7005';
}
let mixin = {
    data() {
        return {
            apiURL: apiURL,
            CASServer: 'https://sso.upc.edu/CAS',
            contactEmail: 'info.biblioteques@upc.edu',
            specialFields: ['id','type','subtype','accessURL','numActivities','file']
        }
    },
    methods: {}
};

// Export mixins
export { mixin, apiURL };