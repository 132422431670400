<!--
--------------------------------------------------------------------------
    TopHeader.vue
--------------------------------------------------------------------------

    Component to build to header

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>

    <div id="header">
        <div class="container">
            <div class="row">
                <div id="title-logo" class="col-sm text-left">
                    <a id="logo" :href="'institutionURL'|translate" target="_blank">
                        <img src="/images/logo.png" :alt="'institutionName'|translate" :title="'institutionName'|translate">
                    </a>
                </div>
                <!-- ============================================================== -->
                <!-- Top menu -->
                <!-- ============================================================== -->
                <div class="col-12 col-sm-9">
                    <b-button-group id="top-menu-mobile" class="mt-3 d-block d-sm-none text-center mb-3">
                        <b-button variant="outline-danger" :to="'/news'|translate" :title="'News'|translate" target="_self">
                            <i class="fas fa-exclamation"></i>
                        </b-button>
                        <b-button variant="outline-secondary" v-for="section in $store.state.sections.filter(s => s.menu === 'top')" :key="'section.mobile.' + section.id" :to="'/' + section.path[$language.current]" :title="section.menuName[$language.current]" :accesskey="section.accessKey">
                            <span aria-hidden="true" role="presentation" class="custom-icon" v-html="section.menuIcon" v-if="section.menuIcon.indexOf('\&\#') !== -1"></span> <i :class="section.menuIcon" v-else></i>
                        </b-button>
                        <b-dropdown variant="outline-secondary" v-if="$store.state.user.username != ''">
                            <template #button-content>
                                <span aria-hidden="true" role="presentation" class="custom-icon">&#xe928;</span>
                            </template>
                            <b-dropdown-item>
                                <router-link :to="'/personal_corner'|translate" :title="'Personal corner'|translate" target="_self">
                                    <span aria-hidden="true" role="presentation" class="custom-icon">&#xe928;</span> <translate>Personal corner</translate>
                                </router-link>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <a href="javascript:void(0);" :title="'Logout'|translate" target="_self" @click="logout()">
                                    <span aria-hidden="true" role="presentation" class="custom-icon">&#xe930;</span> <translate>Logout</translate>
                                </a>
                            </b-dropdown-item>
                        </b-dropdown>
                        <b-button variant="outline-secondary" href="javascript:void(0);" :title="'Login'|translate" target="_self" @click="login()" v-else>
                            <span aria-hidden="true" role="presentation" class="custom-icon">&#xe913;</span>
                        </b-button>
                        <b-dropdown variant="outline-secondary" :text="$language.available[$language.current]">
                            <template #button-content>
                                <span aria-hidden="true" role="presentation" class="custom-icon">world</span> {{ $language.available[$language.current] }}
                            </template>
                            <b-dropdown-item :active="key === $language.current" v-for="(language, key) in $language.available" :key="key" @click="changeLanguage(key)">{{ language }}</b-dropdown-item>
                        </b-dropdown>
                    </b-button-group>

                    <ul id="top-menu" class="mt-3 d-none d-sm-block text-right">

                        <li>
                            <router-link :to="'/news'|translate" :title="'News'|translate" target="_self" class="text-danger">
                                <i class="fas fa-exclamation"></i> <translate>News</translate>
                            </router-link>
                        </li>

                        <li v-for="section in $store.state.sections.filter(s => s.menu === 'top')" :key="'section.' + section.id">
                            <a :href="'/' + section.path[$language.current]" :title="section.menuName[$language.current]" :target="section.newWindow ? '_blank' : '_self'" :accesskey="section.accessKey" v-if="section.type === 'link'">
                                <span aria-hidden="true" role="presentation" class="custom-icon" v-html="section.menuIcon" v-if="section.menuIcon.indexOf('\&\#') !== -1"></span> <i :class="section.menuIcon" v-else></i> {{ section.menuName[$language.current] }}
                            </a>
                            <router-link :to="'/' + section.path[$language.current]" :title="section.menuName[$language.current]" :accesskey="section.accessKey" v-else>
                                <span aria-hidden="true" role="presentation" class="custom-icon" v-html="section.menuIcon" v-if="section.menuIcon.indexOf('\&\#') !== -1"></span> <i :class="section.menuIcon" v-else></i> {{ section.menuName[$language.current] }}
                            </router-link>
                        </li>

                        <li v-if="$store.state.user.username != ''">
                            <b-dropdown size="sm">
                                <template #button-content>
                                    <span aria-hidden="true" role="presentation" class="custom-icon">&#xe928;</span> <translate>Profile</translate>
                                </template>
                                <b-dropdown-item>
                                    <router-link :to="'/personal_corner'|translate" :title="'Personal corner'|translate" target="_self">
                                        <span aria-hidden="true" role="presentation" class="custom-icon">&#xe928;</span> <translate>Personal corner</translate>
                                    </router-link>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="$store.state.user.canImpersonate && $store.state.realUser === null">
                                    <a href="javascript:void(0);" v-b-modal.impersonate>
                                        <span aria-hidden="true" role="presentation" class="custom-icon">&#xe90a;</span> <translate>Impersonate</translate>
                                    </a>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="$store.state.realUser !== null && $store.state.realUser.canImpersonate">
                                    <a href="javascript:void(0);" @click="exitImpersonation">
                                        <span aria-hidden="true" role="presentation" class="custom-icon">&#xe90a;</span> <translate>Exit impersonation</translate>
                                    </a>
                                </b-dropdown-item>
                                <b-dropdown-item>
                                    <a href="javascript:void(0);" :title="'Logout'|translate" target="_self" @click="logout()">
                                        <span aria-hidden="true" role="presentation" class="custom-icon">&#xe930;</span> <translate>Logout</translate>
                                    </a>
                                </b-dropdown-item>
                            </b-dropdown>
                        </li>
                        <li v-else>
                            <a href="javascript:void(0);" :title="'Login'|translate" target="_self" @click="login()">
                                <span aria-hidden="true" role="presentation" class="custom-icon">&#xe913;</span> <translate>Login</translate>
                            </a>
                        </li>

                        <li>
                            <b-dropdown :text="$language.available[$language.current]" size="sm">
                                <template #button-content>
                                    <span aria-hidden="true" role="presentation" class="custom-icon">world</span> {{ $language.available[$language.current] }}
                                </template>
                                <b-dropdown-item :active="key === $language.current" v-for="(language, key) in $language.available" :key="key" @click="changeLanguage(key)">{{ language }}</b-dropdown-item>
                            </b-dropdown>
                        </li>

                    </ul>
                </div>
            </div>
            <div class="row">
                <div id="title" class="col">
                    <router-link to="/" title="FUTUR" accesskey="1">
                        <h1>{{ $gettext($store.state.metadata.title) }}</h1>
                        <span class="subtitle">{{ $gettext($store.state.metadata.subtitle) }}</span>
                    </router-link>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- Main menu -->
        <!-- ============================================================== -->
        <a accesskey="2" href="#content" class="d-none"><translate>Go to the content (press return)</translate></a>
        <a accesskey="6" href="#main-menu" class="d-none"><translate>Navigation</translate></a>
        <div id="main-menu" :style="{ backgroundImage: $store.state.headerImage === '' ? 'url(/images/sections/default.jpg)' : $store.state.headerImage }">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="d-flex flex-wrap justify-content-center align-items-center">

                            <span v-for="section in $store.state.sections.filter(s => s.menu === 'main')" :key="section.id">
                                <a :href="'/' + section.path[$language.current]" :accesskey="section.accessKey" class="d-flex main-menu-element align-items-center" :target="section.newWindow ? '_blank' : '_self'" v-if="section.type === 'link'">
                                    <div class="content">
                                        <i :class="section.menuIcon"></i>
                                        <span class="text">{{ section.menuName[$language.current] }}</span>
                                        <span class="number">1</span>
                                    </div>
                                </a>
                                <router-link :to="'/' + section.path[$language.current] + (section.defaultURLParams ? '/' + section.defaultURLParams[$language.current] : '')" :accesskey="section.accessKey" class="d-flex main-menu-element align-items-center" :key="section.id" v-else>
                                    <div class="content">
                                        <i :class="section.menuIcon"></i>
                                        <span class="text">{{ section.menuName[$language.current] }}</span>
                                        <span class="number">{{ section.numResults }}</span>
                                    </div>
                                </router-link>
                            </span>

                            <span>
                                <a href="https://apren.upc.edu" class="d-flex main-menu-element align-items-center apren" target="_blank" :title="'APRÈN. Website of the Learning Production by UPC Teachers'|translate">
                                    <div class="content">
                                        <img :src="$store.state.config.URL + '/images/apren.png'" :alt="'APRÈN. Website of the Learning Production by UPC Teachers'|translate" style="margin-bottom: 5px;"/>
                                        <span class="text">Aprèn</span>
                                    </div>
                                </a>
                            </span>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- Search box -->
        <!-- ============================================================== -->
        <div id="searcher">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <form name="Search" id="Search" @submit.stop.prevent @submit="executeSearch()" role="form">
                            <div class="form-row align-items-center">
                                <div class="col">
                                    <div class="input-group">
                                        <label for="Text" class="d-none d-sm-flex"><translate>Search</translate></label>
                                        <input id="Text" name="Text" type="text" value="" class="form-control" v-model="$store.state.search.text" :placeholder="'Text to search'|translate" accesskey="4">
                                        <div class="input-group-append">
                                            <b-dropdown :text="$store.state.search.selectionText === 'All' ? $gettext($store.state.search.selectionText) : $store.state.search.selectionText">
                                                <b-dropdown-item :active="$store.state.search.searchSection === null" key="search.all" @click="changeSearchSection(null)"><translate>All</translate></b-dropdown-item>
                                                <b-dropdown-item :active="$store.state.search.searchSection !== null && $store.state.search.searchSection.id === section.id" v-for="section in $store.state.sections.filter(s => s.showInSearch === true)" :key="'search.' + section.id" @click="changeSearchSection(section)">{{ section.fullName[$language.current] }}</b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary button-action" type="submit">
                                                <i class="fas fa-search"></i>
                                            </button>
                                            <button type="button" class="btn btn-default button-action" :title="'Information about search'|translate" v-b-modal.search-info>
                                                <i class="fas fa-info"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <b-modal id="search-info" :title="'Information'|translate" ok-only :ok-title="'Close'|translate">
                            <span v-translate render-html="true">searchInformation</span>
                        </b-modal>
                    </div>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- Breadcrumbs -->
        <!-- ============================================================== -->
        <div id="breadcrumbs" v-if="$store.state.breadcrumbs.length > 0">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <b-breadcrumb :items="$store.state.breadcrumbs" ></b-breadcrumb>
                    </div>
                </div>
            </div>
        </div>

        <!-- ============================================================== -->
        <!-- Modal to impersonate                                           -->
        <!-- ============================================================== -->
        <b-modal id="impersonate" :title="'Impersonate'|translate" v-if="$store.state.user.username != '' && $store.state.user.canImpersonate">
            <b-form @submit.stop.prevent="impersonate" novalidate>
                <b-form-group id="group-user" :label="'Username'|translate" label-for="title">
                    <b-form-input id="username" name="username" v-model="$v.impersonation.username.$model" type="text" :placeholder="'Username'|translate" :state="validateState('username')"></b-form-input>
                    <b-form-invalid-feedback id="title"><translate>Username is required.</translate></b-form-invalid-feedback>
                </b-form-group>
                <br />
            </b-form>
            <template #modal-footer="{ cancel }">
                <b-button type="submit" variant="primary" @click="impersonate"><translate>Send</translate></b-button>
                <b-button variant="secondary" @click="cancel()"><translate>Close</translate></b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import { validationMixin } from 'vuelidate';
    import { required } from 'vuelidate/lib/validators';

    export default {
        name: 'top-header',
        mixins: [validationMixin],
        data() {
            return {
                impersonation: {
                    username: ''
                }
            };
        },
        validations: {
            impersonation: {
                username: { required }
            }
        },
        methods: {
            /**
             * Changes current language
             */
            changeLanguage(language) {
                // Redirect to item or section URL
                if (this.$store.state.currentItem != null) {
                    location.href = this.getItemURL(this.$store.state.currentItem).replace(this.$store.state.config.URL, '') + '?locale=' + language;
                } else if (this.$store.state.currentSection != null) {
                    location.href = '/' + this.$store.state.currentSection.path[language] + '?locale='+ language;
                } else {
                    if (this.$store.state.currentPath != '/')  location.href = this.$gettext(this.$store.state.currentPath, language) + '?locale='+ language;
                    else location.href = '/?locale='+ language;
                }
            },
            /**
             * Execute a text search
             */
            executeSearch() {
                if (this.$store.state.search.searchSection !== null) this.$router.push({ path: '/' + this.$store.state.search.searchSection.path[this.$language.current] + '/t/' + this.UTF8ToB64(this.$store.state.search.text) });
                else this.$router.push({ path: this.$gettext('/search') + '/t/' + this.UTF8ToB64(this.$store.state.search.text) });
            },
            /**
             * Changes current search section
             */
            changeSearchSection(section) {
                if (section === null) this.$store.commit('changeSearchSection', { section: null, text: 'All' } );
                else this.$store.commit('changeSearchSection', { section: section, text: section.fullName[this.$language.current] });
            },
            /**
             * Logout session
             */
            logout() {
                // Do logout on API
                this.axios.get(this.apiURL + '/users/logout')
                    .then(() => {
                        // Set username to null
                        this.$store.commit('setUsername', null);
                        sessionStorage.removeItem('userImpersonate');
                        // Redirect to CAS logout
                        window.location = this.CASServer + '/logout?url=' + encodeURI(location.href);
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Login session
             */
            login() {
                window.location = this.apiURL + '/users/login?returnUrl=' + encodeURI(location.href);
            },
            /**
             * Validates if a form field has a invalida value or not
             */
            validateState(name) {
                const { $dirty, $error } = this.$v.impersonation[name];
                return $dirty ? !$error : null;
            },
            /**
             * Impersonate a user
             */
            impersonate() {
                this.$v.impersonation.$touch();
                // Check if form has errors
                if (!this.$v.impersonation.$anyError) {
                    // Check if user exists
                    this.axios.get(this.apiURL + '/users/' + this.impersonation.username)
                        .then(() => {
                            // Store user data
                            sessionStorage.setItem('userImpersonate', this.impersonation.username);
                            location.reload();
                        })
                        .catch(error => {
                            if (error.response.data.status === 404) this.showMessage(this.$gettext('Error'), this.$gettext('User not found.'), 'danger', false);
                            else this.processError(error);
                        });
                }
            },
            /**
             * Exits impersonation
             */
            exitImpersonation() {
                sessionStorage.removeItem('userImpersonate');
                location.reload();
            }
        }
    }
</script>