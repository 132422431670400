<!--
--------------------------------------------------------------------------
    MainContent.vue
--------------------------------------------------------------------------

    Component to build main content

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div id="content" class="container">
        <div class="row">
            <div class="col-12">
                <Errors></Errors>
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
    import Errors from "@/components/Errors";

    export default {
        name: 'main-content',
        components: {Errors}
    }
</script>