/**
 *
 * main.js
 *
 * Inits Vue router
 *
 * @author Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.
 *
 */

import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

// Add router
Vue.use(VueRouter);

// Define routes and components
const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home'),
        beforeEnter: (to, from, next) => {
            // Set default background in header
            if (document.getElementById('main-menu')) document.getElementById('main-menu').style.backgroundImage = 'url(/images/sections/default.jpg)';
            store.commit('setFirstRoute', false);
            next();
        }
    }
];

// Inits router
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'active',
    routes
});

// Avoid duplicate errors
['push','replace'].forEach(method => {
    const originalMethod = VueRouter.prototype[method];
    VueRouter.prototype[method] = function m(location) {
        return originalMethod.call(this, location).catch(error => {
            if (error.name !== 'NavigationDuplicated') {
                throw error;
            }
        })
    };
});

export default router;