/**
 *
 * main.js
 *
 * Inits application
 *
 * @author Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.
 *
*/

import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';
import BootstrapVue from 'bootstrap-vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';
import GetTextPlugin from 'vue-gettext';
import translations from '../translations/translations.json';
import { mixin, apiURL } from './mixin';
import globalPlugin from './plugins/global';

// Import css
import './styles/styles.scss';

Vue.config.productionTip = false;

// Add bootstrap
Vue.use(BootstrapVue);

// Add gettext for translations
Vue.use(GetTextPlugin, {
	autoAddKeyAttributes: true,
	availableLanguages: {
		ca: 'Català'
	},
	defaultLanguage: 'ca',
	silent: true,
	translations: translations
});

// Create tag for translate inside components
Vue.filter('translate', value => {
	return value ? Vue.prototype.$gettext(value.toString()) : '';
});

// Add cookies management
Vue.use(VueCookies);

// Add axios
Vue.use(VueAxios, axios);

// Set axios interceptor
axios.interceptors.request.use((config) => {
	store.commit('cleanErrors');
	return config;
});

// Set mixins
mixin.data.apiURL = apiURL;
Vue.mixin(mixin);

// Set global plugin
Vue.use(globalPlugin);

// Fix leaflet map icons
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Axios Client-ID Header
axios.defaults.headers.common['Client-ID'] = 'FUTUR-20031533201504';

// Load config and fields data before create app
axios.all([axios.get(apiURL + '/config'), axios.get(apiURL + '/fields')])
	.then(axios.spread((...responses) => {
		// Store config data and fields
		store.commit('setConfig', responses[0].data);
		store.commit('setFields', responses[1].data);

		// Inits Vue
		new Vue({
			router,
			store,
			render: h => h(App)
		}).$mount('#app');
	}))
	.catch(() => {
		store.commit('closeLoading');
		store.commit('setOutOfService', true);

		// Inits Vue
		new Vue({
			router,
			store,
			render: h => h(App)
		}).$mount('#app');
	});