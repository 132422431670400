<!--
--------------------------------------------------------------------------
    BottomFooter.vue
--------------------------------------------------------------------------

    Component to build footer

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>

    <footer>

        <div id="partners" v-if="partners.length > 0">
            <a :href="partner.link[$language.current]" target="_blank" :title="partner.title[$language.current]" v-for="partner in partners" :key="partner.id">
                <img :src="$store.state.config.URL + '/images/' + partner.image" :alt="partner.title[$language.current]" :title="partner.title[$language.current]" class="grayscale">
            </a>
        </div>

        <div id="institution-bottom-menu">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-3">
                        <a :href="'institutionURL'|translate" target="_blank">
                            © <translate>institutionName</translate>
                        </a>.
                        <a href="'unitURL'|translate" target="_blank">
                            <translate>unitName</translate>
                        </a>
                        <br>
                        {{ contactEmail }}
                    </div>
                    <div id="bottom-menu" class="col-12 col-sm">
                        <ul>
                            
                            <li>
                                <span v-translate>Last update: </span> {{ $store.state.config.lastUpdate }}
                            </li>

                            <li>
                                <span v-translate>Next update: </span> {{ $store.state.config.nextUpdate }}
                            </li>

                            <li>
                                <router-link :to="'/sitemap'|translate" :title="'Sitemap'|translate" target="_self" accesskey="3">
                                    <translate>Sitemap</translate>
                                </router-link>
                            </li>

                            <li v-for="section in $store.state.sections.filter(s => s.menu === 'bottom')" :key="section.id">
                                <a :href="'/' + section.path[$language.current]" :title="section.menuName[$language.current]" :target="section.newWindow ? '_blank' : '_self'" :accesskey="section.accessKey" v-if="section.type === 'link'">
                                    {{ section.menuName[$language.current] }}
                                </a>
                                <router-link :to="'/' + section.path[$language.current]" :title="section.menuName[$language.current]" :accesskey="section.accessKey" v-else>
                                    {{ section.menuName[$language.current] }}
                                </router-link>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </footer> 

</template>

<script>
export default {
    name: 'BottomFooter',
    data() {
        return {
            partners: []
        }
    },
    // Called when component is created
    created() {
        // Get partners and sections in bottom menu
        this.getPartners();
    },
    methods: {
        /**
         * Get partners
         */
        getPartners() {
            this.axios.get(this.apiURL + '/partners')
                .then(response => {
                   this.partners = response.data;
                })
                .catch(error => {
                    this.processError(error);
                });
        }
    }
}
</script>

